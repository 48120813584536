import shopifyGenericDefaults from '../shopify-generic/config.js';

const paletteColors = [
  ['Brown', '4f2907'],
  ['Pink', 'eb6fa4'],
  ['Peacock', '008080'],
  ['Red', 'c00718'],
  ['Gray', '808080'],
  ['Blue', '1f5e9e'],
  ['Tan', 'dac8a0'],
  ['Yellow', 'ffd246'],
  ['Purple', '67357e'],
  ['Light Blue', '90c1e4'],
  ['Peach', 'fbbd93'],
  ['Black', '000000'],
  ['Green', '248a0f'],
  ['Burgundy', '750f17'],
  ['Orange', 'fa760a'],
  ['Gainsboro', 'dfdddd'],
  ['Pearl', 'f0ead6'],
  ['Ivory', 'fffff0'],
];

const initQuickActions = (productsContainer) => {
  if (productsContainer) {
    window.export.ProductItem();
  }
};

const defaultUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;
const updateCallback = () => {
  defaultUpdateCallback();
  initQuickActions(window.document.querySelector('.cm_SearchResult .collection__products'));
};

function getRelatedSelection(itemData, fieldsForRelated) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

const getSelection = (itemData) => getRelatedSelection(itemData, ['Styles', '_Color']);

window.Convermax.getSearchLink = (itemData) =>
  window.Convermax.createSearchUrl(
    {
      selection: [
        ...getRelatedSelection(itemData, ['Styles', '_Color']),
        { field: 'Use', term: 'Top-Brands' },
      ],
    },
    { goToSearchPage: true },
  );

// Move related items cards to native slider container
// It's implementation make too hard to mimic it, so we fill it with our data
function onItemsRendered() {
  const cmRelatedItemsContainer = window.document.querySelector(
    '#cm-related-products-footer .cmRepeater_items',
  );

  // Transplant our items content to the placeholders at slider
  cmRelatedItemsContainer.querySelectorAll('.product-item').forEach((item) => {
    const placeholder = window.document.querySelector('.cm-related-products_placeholder');

    placeholder.innerHTML = item.innerHTML;
    placeholder.classList = item.classList;
    [...item.attributes].forEach((attr) => {
      placeholder.setAttribute(attr.nodeName, attr.nodeValue);
    });
  });

  // Remove unused placeholders
  window.document
    .querySelectorAll('.cm-related-products_placeholder')
    .forEach((placeholder) => placeholder.remove());

  cmRelatedItemsContainer.remove();

  initQuickActions(window.document.querySelector('.cm_related-products__custom .scroll-slider__slider'));
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  product: {
    getImageToExtractColors: () =>
      window.document
        .querySelector('.template-product .product__inner .product__media .image__img')
        .src.replace('&width=320', ''),

    noImageSrc:
      'https://cdn.shopify.com/s/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c.gif',
    searchResultsImageWidth: 800,
    searchResultsImageHeight: 800,
  },
  ColorEngineFeature: true,
  SearchRequestDefaults: {
    pageSize: 48,
  },
  requestTimeout: 25000,
  facets: {
    advancedColorFacet: { fields: ['_Color'], paletteColors },
    sizeFacet: {
      fields: ['Size'],
      step: 'imperial',
      childFields: ['Width', 'Length'],
      singleFacet: true,
    },
    rangedFacet: [{ fields: ['Thickness'], name: 'thicknessFacet', step: '1/100' }],
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) =>
        ![
          'SearchHeader',
          'FilterChips',
          'SearchBoxDialogButton',
          'FacetDialog',
          'RelatedItems',
          'SearchResult',
        ].includes(w.name),
    ),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        '_popularity:desc': 'Popularity',
        'review_score:desc': 'Customer Review',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        selector: '#cm-mobile-search-box',
        class: 'cm-mobile-search-box',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initCollapsed: window.Convermax.config?.facetDialogInitCollapsed ?? true,
      initExpandedFacets: ['stock'],
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields: [
        ...shopifyGenericDefaults.Widgets.find((w) => w.name === 'FilterChips').ignoreFields,
        'stock',
      ],
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products-footer',
      template: 'product/relatedItems',
      count: window.Convermax.config?.relatedItemsCount || 11,
      getSelection,
      onItemsRendered,
    },
    {
      name: 'SearchResult',
      instantInfiniteScroll: true,
      updateCallback,
    },
    {
      name: 'ProductColorsDesktop',
      type: 'RelatedColors',
      location: '.for-desktop #cm_product-colors',
      template: 'product/relatedColors',
    },
    {
      name: 'ProductColorsMobile',
      type: 'RelatedColors',
      location: '.for-mobile #cm_product-colors',
      template: 'product/relatedColors',
    },
    {
      name: 'CoordinatingItemsDesktop',
      type: 'ProductData',
      location: '.for-desktop #cm_coordinating-items',
      template: 'product/productData',
    },
    {
      name: 'CoordinatingItemsMobile',
      type: 'ProductData',
      location: '.for-mobile #cm_coordinating-items',
      template: 'product/productData',
    },
    {
      name: 'SimpleColorPaletteDialog',
      field: '_Color',
      template: 'colorSearch/simplePaletteDialog',
    },
    {
      name: 'ColorExtractorDialog',
      field: '_Color',
      template: 'colorSearch/colorExtractorDialog',
    },
  ],
};
