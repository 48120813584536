//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-732:_1098,_862,_658,_4526,_952,_3608,_3040,_8632;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-732')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-732', "_1098,_862,_658,_4526,_952,_3608,_3040,_8632");
        }
      }catch (ex) {
        console.error(ex);
      }